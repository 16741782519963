<template>
    <div>
        <v-app-bar
      app
      color="#0382B3"
      dense
      dark
    >
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <v-toolbar-title>ระบบใบอนุญาต</v-toolbar-title>

      <v-spacer></v-spacer>

    </v-app-bar>
    </div>
</template>

<script>
export default {
    name: 'LicenseHeader',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');
#app {
  font-family: 'Kanit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #067ff8;
}

#title {
  font-family: 'Kanit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #000000;

}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #000000;
    &.router-link-exact-active {
      color: #070bff;
    }
  }
}

</style>