<template>
 <v-content>
      <router-view>
          
      </router-view>
  </v-content>
</template>

<script>
export default {
  name: "LicenseContent",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
