<template>
    <v-container>
         <!-- <h1>home ใบอนุญาต</h1> -->
    </v-container>
       
       
    
</template>

<script>
export default {
    name: 'LicenseHome',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>