import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import License1 from "../views/LicenseA.vue";
import License2 from "../views/LicenseB.vue";
import License3 from "../views/LicenseC.vue";
import License4 from "../views/LicenseD.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/License1",
    name: "license1",
    component: License1,
  },
  {
    path: "/License2",
    name: "license2",
    component: License2,
  },
  {
    path: "/License3",
    name: "license3",
    component: License3,
  },
  {
    path: "/License4",
    name: "license4",
    component: License4,
  },
  {
    path: "/",
    redirect: "Home"
  },
  {
    path: "*",
    redirect: "Home"
  }
 
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
