<template>
  <v-navigation-drawer color="#aaa7a7" permanent app>
    <center><v-img src="@/assets/logotop.png" alt="" width="70%" /></center>

    <v-divider></v-divider>

    <v-list dense shaped>
      <v-subheader color="#FFFFFF">ประเภทใบอนุญาต</v-subheader>
      <v-list-item-group v-model="selectedMenu" mandatory color="#FFFFFF">
        <v-list-item
          v-for="([icon, text, route], i) in Mainmenus"
          :key="i"
          link
          @click="onClickMenu(route)"
        >
          <v-list-item-icon>
            <v-icon>
              {{ icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content color="#FFFFFF">
            <v-list-item-title>
              {{ text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <!-- <v-subheader>การลา</v-subheader>
      <v-list-item-group color="primary">
        <v-list-item
          v-for="([icon, text, route], i) in Leavemenus"
          :key="i"
          link
          @click="onClickMenu(route)"
        >
          <v-list-item-icon>
            <v-icon>
              {{ icon }}
            </v-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item-icon>
        </v-list-item>
      </v-list-item-group>

      <v-subheader>รายงาน</v-subheader>
      <v-list-item-group color="primary">
        <v-list-item
          v-for="([icon, text, route], i) in Reportmenus"
          :key="i"
          link
          @click="onClickMenu(route)"
        >
          <v-list-item-icon>
            <v-icon>
              {{ icon }}
            </v-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item-icon>
        </v-list-item>
      </v-list-item-group> -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "LicenseMenu",

  methods: {
    onClickMenu(link) {
      this.$router.push(link).catch((err) => {});
    },
  },
  data() {
    return {
      selectedMenu: 0,
      Mainmenus: [
        ["mdi-apps-box", "ใบอนุญาตก่อสร้าง ก", "/License1"],
        ["mdi-apps-box", "ใบอนุญาตก่อสร้าง ข", "/License2"],
        ["mdi-apps-box", "ใบอนุญาตก่อสร้าง ค", "/License3"],
        ["mdi-apps-box", "ใบอนุญาตก่อสร้าง อ", "/License4"],
      ],
      // Reportmenus: [
      //   ["mdi-chart-areaspline", "รายงานลาทั้งหมด", "/stock"],
      //   ["mdi-chart-areaspline", "รายงานตามแผนก", "/stock"],
      //   ["mdi-chart-areaspline", "รายงานรายพนักงาน", "/stock"],
      //   ["mdi-chart-areaspline", "รายงานลาตามวัน", "/stock"],
      //   ["mdi-chart-areaspline", "รายงานการมาสาย", "/report"],
      // ],
      // Leavemenus: [["mdi-view-comfy", "บันทึกการลา", "/Leave"]],
    };
  },
};
</script>

<style lang="scss" scoped></style>
